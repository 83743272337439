import {
  StylistsCertifications,
  SystemOptions
} from '../../../../../config/enums'

export default {
  [SystemOptions.MICROLINES]: {
    title: '<strong class="pr-2">MicroLines</strong> Topper',
    btnBoxText: '<span>Become a<br/> Hairdreams partner</span>',
    btnText: 'Start here',
    items: {
      1: {
        id: 1,
        img: '1-stylists-microlines.gif',
        video: 'https://player.vimeo.com/video/866563812?h=ac4f513217',
        text: "This topper is nothing like a wig. <br/> It's lighter and has an open-air mesh that lets your client's scalp breathe. <br/>       It's permanently attached and integrated with their own hair. <br/>  It styles and feels like their own.",
        hasAction: true
      },
      2: {
        id: 2,
        img: '2-stylists-microlines.png',
        video: '',
        text: '',
        hasAction: false
      },
      3: {
        id: 3,
        img: '3-stylists-microlines.png',
        video: 'https://player.vimeo.com/video/884999751?h=c81d098d44',
        text: 'Each MicroLines piece is handcrafted taking 3 weeks to produce. Our manufacturing specialists painstakenly tie each hair by hand to the mesh for the most naural looking integration piece.',
        hasAction: true
      },
      4: {
        id: 4,
        img: '4-stylists-microlines.png',
        video: 'https://player.vimeo.com/video/866609811?h=ca0028639a',
        text: "Lets your client's scalp breathe by utilizing a hypoallergenic mesh that's totally open.",
        hasAction: true
      },
      5: {
        id: 5,
        img: '5-stylists-microlines.png',
        video: 'https://player.vimeo.com/video/732572685?h=14e4e2940a',
        text: '',
        hasAction: true
      },
      6: {
        id: 6,
        img: '6-stylists-microlines.png',
        video: '',
        text: '',
        hasAction: false
      },
      7: {
        id: 7,
        img: '7-stylists-microlines.png',
        video: '',
        text: '',
        hasAction: false
      },
      8: {
        id: 8,
        img: '8-stylists-microlines.png',
        video: '',
        text: 'We have 10 types, 23 colors and 3 length options. Each MicroLines piece can be customized to further fit specific hair loss needs.',
        hasAction: true
      },
      9: {
        id: 9,
        img: '9-stylists-microlines-min-new.png',
        video: '',
        text: 'Handcrafted 7-STAR MicroLines last for up to 2 years with regular maintenance appointments and refresh services.',
        hasAction: true
      },
      10: {
        id: 10,
        img: '10-stylists-microlines.png',
        video: 'https://player.vimeo.com/video/916289032?h=7764a8ea1b',
        showVideoOnCertPage: false,
        text: 'Based on your education and experience, we offer various training programs to best prepare you for a successful MicroLines business.',
        certificationText: {
          [StylistsCertifications.ML_REGULAR]: {
            title1: 'Class Description',
            subtitle1: 'MicroLines Topper',
            subtitle2: 'Regional Class',
            htmlText1:
              '<span>This comprehensive course combines an in-depth web module training + an in person hands-on class where you will apply, cut, and style the MicroLines hair topper integration piece on a live model that you will need to provide. All models must be pre-approved. The kit and course also includes a special "Half Hair" practice piece where you will first learn the basics of application, cutting, removal, and reapplication on a mannequin head. Training additionally covers maintenance, consultation and marketing.<br/> <br/> The Start Kit can certify up to <strong>two</strong> licensed cosmetologists. <br/> <br/> <a href="https://iwanthairdreams.com/pages/training-calendar" target="_blank">See the Training Calendar for dates and locations.</a></span>'
          },
          [StylistsCertifications.ML_CUTTING]: {
            title1: 'Class Description',
            subtitle1: 'MicroLines Topper',
            subtitle2: 'Regional Class',
            htmlText1:
              '<span>This comprehensive course combines an in depth web module training + an in person hands-on class where you will apply, cut, and style the MicroLines hair topper integration piece on a live model that you will need to provide. All models must be pre-approved. The kit and course also includes a special "Half Hair" practice piece where you will first learn the basics of application, cutting, removal, and reapplication on a mannequin head. Training additionally covers maintenance, consultation and marketing. <br/> <br/> The Start Kit can certify up to <strong>two</strong> licensed cosmetologists. <br/> <br/> <a href="https://iwanthairdreams.com/pages/training-calendar" target="_blank">See the Training Calendar for dates and locations.</a></span>'
          }
        },
        hasAction: true,
        popUpBtnText: 'Start here'
      },
      11: {
        id: 11,
        img: '11-stylists-microlines.png',
        video: 'https://player.vimeo.com/video/853496606?h=4be6c65984',
        text: 'With our 7-STAR hair, each individual hair is hand-selected, one at a time, for quality and color. Only 50% passes our strict quality requirements. The result? You get the best hair in the world. Period.',
        hasAction: true
      },
      12: {
        id: 12,
        img: '12-stylists-microlines.png',
        video: 'https://player.vimeo.com/video/885041497?h=ef373cc7e6',
        text: '',
        hasAction: true
      },
      13: {
        id: 13,
        img: '13-stylists-microlines.png',
        popUpImg: '13-stylists-microlines-popup.png',
        video: '',
        text: 'Our hair extensions are made of real human hair with the cuticle intact and damage free, scientifically proven in studies done at University of Aachen Germany.* Other hair extension brands (reviewed in the same study) showed up to 80% damaged hair. <br/> <br/> The lower quality is caused by partially stripping the cuticle, then coating with silicone, giving it an "artificial" shine. After a few washes the coating is stripped away, and the hair becomes dull and lifeless. A no-go for high-quality Hairdreams hair. <br/> <br/> *Electron microscope study, Prof. Dr. rer. nat. Martin Möller',
        hasAction: true
      }
    }
  },
  [SystemOptions.LASERBEAMER]: {
    title:
      '<strong>Laserbeamer</strong><span class="pl-2">Nano Extensions</span>',
    btnBoxText: '<span>Become a <br/> Hairdreams partner</span>',
    btnText: 'Start here',
    items: {
      1: {
        id: 1,
        img: '1-stylists-laserbeamer.png',
        video: '',
        text: '<span>With the Laserbeamer Nano a new era of Flat-Fusion extensions starts. It heats the bonds at a safe temperature, molds each bond into a uniform shape, and then seals it. This produces bonds that can last up to 6 months, without any overdirection of the natural hair making this the safest bonded method out there. The end result combines the benefits of flat extensions such as tape-ins with the longevity and flexibility of bonded extensions.</span>',
        hasAction: true
      },
      2: {
        id: 2,
        img: '2-stylists-laserbeamer.png',
        video: '',
        text: '<span>The Laserbeamer Nano applies a full head of extensions in under an hour. Other bonded extensions take hours. The Nano method is so much faster, making you more money per minute.</span>',
        hasAction: true
      },
      3: {
        // https://player.vimeo.com/video/217095139
        id: 3,
        img: '3-stylists-laserbeamer.png',
        video: 'https://player.vimeo.com/video/853496565?h=9b65a13d87',
        text: '',
        hasAction: true
      },
      4: {
        id: 4,
        img: '4-stylists-laserbeamer.png',
        video: '',
        text: '<span>When you choose our 7-STAR hair, each individual hair is hand-selected, one at a time, for quality and color. Only 50% passes our strict quality requirements. The result? You get the best hair in the world. Period.</span>',
        hasAction: true
      },
      5: {
        id: 5,
        img: '5-stylists-laserbeamer.png',
        popUpImg: '5-stylists-laserbeamer-popup.jpg',
        video: '',
        text: '<span>9 textures for every hair type <br/> <br/> From super straight to slightly wavy and tight curls – Hairdreams hair can be ordered and made into 9 different hair textures!</span>',
        hasAction: true
      },
      6: {
        id: 6,
        img: '6-stylists-laserbeamer.png',
        video: 'https://player.vimeo.com/video/853496606?h=4be6c65984',
        text: 'When you choose our 7-STAR hair, each individual hair is hand-selected, one at a time, for quality and color. Only 50% passes our strict quality requirements. The result? You get the best hair in the world. Period.',
        hasAction: true
      },
      7: {
        id: 7,
        img: '7-stylists-laserbeamer.png',
        popUpImg: '7-stylists-laserbeamer-popup.png',
        video: '',
        text: 'Our hair extensions are made of real human hair with the cuticle intact and damage free, scientifically proven in studies done at University of Aachen Germany.* Other hair extension brands (reviewed in the same study) showed up to 80% damaged hair. <br/> <br/> The lower quality is caused by partially stripping the cuticle, then coating with silicone, giving it an "artificial" shine. After a few washes the coating is stripped away, and the hair becomes dull and lifeless. A no-go for high-quality Hairdreams hair. <br/> <br/> *Electron microscope study, Prof. Dr. rer. nat. Martin Möller',
        hasAction: true
      },
      8: {
        id: 8,
        img: '8-stylists-laserbeamer.png',
        video: '',
        text: `
        Reasons why Nanos can be worn for up to 6 months without moving them up: <br/> 
        <br/>        
        1. The Bond Size <br/>        
        Nanos are small and flat, and they will stay undetectable during the wearing period.<br/> <br/>      
        2. The Placement <br/>        
        Nanos are placed at zero elevation, keeping the tension even on the client's hair where the extensions are attached. This allows for a 6 month wearing period without damage. <br/>
        <br/>
        3. The Application <br/> 
        The natural hair is held in the machine straight during application, which avoids twisting that causes matting during the wearing period. This unique feature allows for lowest maintenance extension application.`,
        hasAction: true
      },
      9: {
        id: 9,
        img: '9-stylists-laserbeamer.png',
        video: '',
        text: "Nano bonds are made of a heat activated formula. This proprietary material doesn't stick or adhere but instead 'hugs' the hair, making it ideal for damage-free wear and removal.",
        hasAction: true
      },
      10: {
        id: 10,
        img: '10-stylists-laserbeamer.png',
        video: '',
        text: 'The Laserbeamer Nano applies 5 flat bonds in 35 seconds, making it the fastest fusion application available. With this extraordinary automatic machine, you can apply a full-head of extensions in under one hour.',
        hasAction: true
      },
      11: {
        id: 11,
        img: '11-stylists-laserbeamer.png',
        popUpImg: '11-stylists-laserbeamer-popup.jpg',
        video: '',
        text: 'EXTRA HAIR LENGTH AND FULLNESS <br/>      Hairdreams offers not only the best hair quality, but also significantly more hair! The hair is always longer than officially stated, resulting in maximum fullness to the ends. <br/> <br/>        Advantage: fewer Nanos needed, less cutting to blend in as well as less styling, more hairstyle options and a more natural look!',
        hasAction: true
      },
      12: {
        id: 12,
        img: '12-stylists-laserbeamer.png',
        video: '',
        text: "What a timesaver! We pre-made color-blended cartridges eliminating the time-consuming prep work. Our dual color-blends provide depth and dimension for the most natural look. And guess what? You can easily rearrange the colors to suit your client's needs.",
        hasAction: true
      },
      13: {
        id: 13,
        img: '13-stylists-laserbeamer.png',
        video: 'https://player.vimeo.com/video/885041497?h=ef373cc7e6',
        text: '',
        hasAction: true
      },
      14: {
        id: 14,
        img: '14-stylists-laserbeamer.png',
        showVideoOnCertPage: false,
        video:
          'https://player.vimeo.com/video/916288795?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        text: 'Based on your education and experience, we offer various training programs to best prepare you for a successful Laserbeamer Nano business.',
        certificationText: {
          [StylistsCertifications.LB_HALF]: {
            title1: 'Class Description',
            subtitle1: 'Laserbeamer Nano Automatic Extensions',
            subtitle2: 'Online class',
            htmlText1:
              '<span>This comprehensive course combines an in-depth web module training + a live Zoom hands-on class where you will apply, cut, and style Laserbeamer Nano extensions to one half of a mannequin head. Training additionally covers cutting, styling, removal, maintenance, consultation and marketing. A Live Zoom training date will be scheduled once the online training module is completed.<br/> <br/> The Start Kit can only certify <strong>one</strong> licensed cosmetologist.</span>'
          },
          [StylistsCertifications.LB_FULL]: {
            title1: 'Class Description',
            subtitle1: 'Laserbeamer Nano Automatic Extensions',
            subtitle2: 'Online class',
            htmlText1:
              '<span>This comprehensive course combines an in-depth web module training + a live Zoom hands-on class where you will apply, cut, and style a full head of Laserbeamer Nano extensions to a mannequin head. Training additionally covers removal, maintenance, consultation and marketing. Live Zoom training date will be scheduled once the online training module is completed. <br/> <br/> The Start Kit can only certify <strong>one</strong> licensed cosmetologist.'
          }
        },
        popUpBtnText: 'Start here',
        hasAction: true
      }
    }
  },
  [SystemOptions.FREESTYLER]: {
    title: '<strong class="pr-2">FreeStyler </strong>Bonded Extensions',
    btnBoxText: '<span>Become a <br/>Hairdreams partner</span>',
    btnText: 'Start here',
    items: {
      1: {
        id: 1,
        img: '1-stylists-freestyler.png',
        video: 'https://player.vimeo.com/video/885036965?h=ac9968dda8',
        text: '',
        hasAction: true
      },
      2: {
        id: 2,
        img: '2-stylists-freestyler.png',
        video: '',
        text: '',
        hasAction: false
      },
      3: {
        id: 3,
        img: '3-stylists-freestyler.png',
        video: 'https://player.vimeo.com/video/868980609?h=3294362878',
        text: '',
        hasAction: true
      },
      4: {
        id: 4,
        img: '4-stylists-freestyler.png',
        video: 'https://player.vimeo.com/video/853496606?h=4be6c65984',
        text: 'When you choose our 7-STAR hair, each individual hair is hand-selected, one at a time, for quality and color. Only 50% passes our strict quality requirements. The result? You get the best hair in the world. Period.',
        hasAction: true
      },
      5: {
        id: 5,
        img: '5-stylists-freestyler.png',
        video: '',
        text: "Unlike wefts or clip-ins, which could cause damage, the FreeStyler is ideal for thin hair. Why? With three different bond sizes, you can perfectly match the extension hair density to client's hair density.",
        hasAction: true
      },
      6: {
        id: 6,
        img: '6-stylists-freestyler.png',
        video: 'https://player.vimeo.com/video/853496606?h=4be6c65984',
        text: 'When you choose our 7-STAR hair, each individual hair is hand-selected, one at a time, for quality and color. Only 50% passes our strict quality requirements. The result? You get the best hair in the world. Period.',
        hasAction: true
      },
      7: {
        id: 7,
        img: '7-stylists-freestyler.png',
        popUpImg: '7-stylists-freestyler-popup.png',
        video: '',
        text: 'Our hair extensions are made of real human hair with the cuticle intact and damage free, scientifically proven in studies done at University of Aachen Germany.* Other hair extension brands (reviewed in the same study) showed up to 80% damaged hair. <br/> <br/> The lower quality is caused by partially stripping the cuticle, then coating with silicone, giving it an "artificial" shine. After a few washes the coating is stripped away, and the hair becomes dull and lifeless. A no-go for high-quality Hairdreams hair. <br/> <br/> *Electron microscope study, Prof. Dr. rer. nat. Martin Möller',
        hasAction: true
      },
      8: {
        id: 8,
        img: '8-stylists-freestyler.png',
        video: '',
        text: `Reasons why the FreeStyler can be worn for up to 6 months without moving them up:  <br/> 
        <br/>        
        1. The Bond Size <br/>       
        The FreeStyler bonds are small, and with three different bond sizes you can perfectly match the extension hair density to the client's hair density. They will stay undetectable during the wearing period.<br/> 
        <br/>            
        2. The Placement <br/>        
        The FreeStyler bonds are placed at zero elevation, keeping the tension even on the client's hair where the extensions are attached. This allows for a 6-month wearing period without damage. `,
        hasAction: true
      },
      9: {
        id: 9,
        img: '9-stylists-freestyler.png',
        video: '',
        text: "FreeStyler bonds are made of a heat-activated formula. This proprietary material doesn't stick or adhere, but instead 'hugs' the hair, making it ideal for damage-free wear and removal.",
        hasAction: true
      },
      10: {
        id: 10,
        img: '10-stylists-freestyler.png',
        popUpImg: '10-stylists-freestyler-popup.jpg',
        video: '',
        text: '<span>9 textures for every hair type <br/> <br/> From super straight to slightly wavy and tight curls – Hairdreams hair can be ordered and made into 9 different hair textures!</span>',
        hasAction: true
      },
      11: {
        id: 11,
        img: '11-stylists-freestyler.png',
        popUpImg: '11-stylists-freestyler-popup.jpg',
        video: '',
        text: 'EXTRA HAIR LENGTH AND FULLNESS <br/>        Hairdreams offers not only the best hair quality, but also significantly more hair! The hair is always longer than officially stated, resulting in maximum fullness to the ends. <br/> <br/>        Advantage: fewer FreeStyler strands needed, less cutting to blend in as well as less styling, more hairstyle options and a more natural look!',
        hasAction: true
      },
      12: {
        id: 12,
        img: '12-stylists-freestyler.png',
        video: 'https://player.vimeo.com/video/916288063?h=7a0d286731',
        showVideoOnCertPage: false,
        text: 'Based on your education and experience, we offer various training programs to best prepare you for a successful FreeStyler business.',
        certificationText: {
          [StylistsCertifications.FS_ONLINE]: {
            title1: 'Class Description',
            subtitle1: 'FreeStyler Bonded Extensions',
            subtitle2: 'Online class',
            htmlText1:
              "<span>This comprehensive course combines an in-depth web module training + a live Zoom hands-on class where you will apply, cut, and style Hairdreams bonded extensions to one half of a mannequin head. The course provides the foundation for creating a strand-by-strand fusion application using Hairdreams' proprietary, fast, and efficient selector system. Training additionally covers removal, maintenance, consultation, and marketing. A live Zoom training date will be scheduled once the online training module is completed. <br/> <br/> The Start Kit can only certify <strong>one</strong> licensed cosmetologist. </span>"
          },
          [StylistsCertifications.FS_IN_PERSON]: {
            title1: 'Class Description',
            subtitle1: 'FreeStyler Bonded Extensions',
            subtitle2: 'In-person Class*',
            htmlText1:
              "<span>This comprehensive course combines an in-depth web module training + a 1-day in-salon class. It includes all the equipment and extras needed, PLUS a full set of Hairdreams bonded hair extensions that you will apply on a live model, which you will need to provide. All models must be pre-approved. The live portion of the course is totally hands-on, giving up to <strong>two</strong> stylists the foundation for creating a strand-by-strand fusion lengthening application using Hairdreams' proprietary fast and efficient selector system. <br/> <br/> *The class will be held and scheduled at your salon location. All in-salon costs are included in the Start Kit price.</span>"
          }
        },
        popUpBtnText: 'Start here',
        hasAction: true
      },
      13: {
        id: 13,
        img: '13-stylists-freestyler.png',
        video: 'https://player.vimeo.com/video/885041497?h=ef373cc7e6',
        text: '',
        hasAction: true
      }
    }
  },
  [SystemOptions.TAPE]: {
    title:
      '<strong>QUIKKIES & SECRETS</strong> <span class="pl-2">Tape-ins</span>',
    btnBoxText: '<span>Become a <br/> Hairdreams partner</span>',
    btnText: 'Start here',
    items: {
      1: {
        id: 1,
        img: '1-stylists-tape.png',
        video: 'https://player.vimeo.com/video/732572878?h=94bfd2f8c5',
        text: "Hairdreams Tape-In extensions are a great option for first-time extension clients as they're semi-permanent which means you can wear them 6-8 weeks, take a break and wear them again up to 3 times. The hair is 100% Remy human hair and comes in 2 types: Quikkies Original and Secrets Invisible tape-ins. Secrets appear to grow right out of the scalp and blend in really well. You can combine Secrets with Quikkies tape-ins or other Hairdreams systems for luxurious length and volume.",
        hasAction: true
      },
      2: {
        id: 2,
        img: '2-stylists-tape.png',
        video: 'https://player.vimeo.com/video/868938528?h=ad09574a2d',
        text: 'Secrets are made with a unique 3D Imprint technology where all the hair comes through the tape, so it appears that it grows right out of the scalp. The result? They can be placed higher up and blend in really well with your own hair. Ideal for fine hair customers who are worried that other extensions might show.',
        hasAction: true
      },
      3: {
        id: 3,
        img: '3-stylists-tape.png',
        video: 'https://player.vimeo.com/video/868939356?h=06d08776d2',
        text: '',
        hasAction: true
      },
      4: {
        id: 4,
        img: '4-stylists-tape.png',
        video: '',
        text: 'Our 5-STAR Hairdreams hair is high-quality, healthy real hair with the cuticle intact and a natural shine: <br/><br/>        - Made of healthy, dark human hair <br/>        - Carefully lightened over several weeks using a patented oxidation process and toned to the required color <br/>       - Great bounce and elasticity',
        hasAction: true
      },
      5: {
        id: 5,
        img: '5-stylists-tape.png',
        video: '',
        text: 'Our tape-ins can be worn for 4 to 6 weeks before they need to be moved up. <br/>        They can be reused several times with a total wear time of up to 6 months.',
        hasAction: true
      },
      6: {
        id: 6,
        img: '6-stylists-tape.png',
        video: '',
        text: '',
        hasAction: false
      },
      7: {
        id: 7,
        img: '7-stylists-tape.png',
        popUpImg: '7-stylists-tape-popup.png',
        video: '',
        text: 'Our hair extensions are made of real human hair with the cuticle intact and damage free, scientifically proven in studies done at University of Aachen Germany.* Other hair extension brands (reviewed in the same study) showed up to 80% damaged hair. <br/> <br/> The lower quality is caused by partially stripping the cuticle, then coating with silicone, giving it an "artificial" shine. After a few washes the coating is stripped away, and the hair becomes dull and lifeless. A no-go for high-quality Hairdreams hair. <br/> <br/> *Electron microscope study, Prof. Dr. rer. nat. Martin Möller',
        hasAction: true
      },
      8: {
        id: 8,
        img: '8-stylists-tape.png',
        video: 'https://player.vimeo.com/video/868947324?h=76e99dbfb5',
        text: '        Our 5-STAR Hairdreams hair is high-quality, healthy real hair with the cuticle intact and a natural shine: <br/> <br/>              - Made of healthy, dark human hair  <br/>        - Carefully lightened over several weeks using a patented oxidation process and toned to the required color   <br/>       - Great bounce and elasticity',
        hasAction: true
      },
      9: {
        id: 9,
        img: '9-stylists-tape.png',
        video: '',
        text: 'Our tape-ins are adaptable to any hair density, from thick to thin, to avoid any damage to your hair. ',
        hasAction: true
      },
      10: {
        id: 10,
        img: '10-stylists-tape.png',
        video: 'https://player.vimeo.com/video/885041497?h=ef373cc7e6',
        text: '',
        hasAction: true
      }
    }
  }
}
